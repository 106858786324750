import React, { FC, useCallback, useState } from 'react'
import { ModalID } from '@netpurpose/types'

type IModalContext = {
  isModalOpen: Record<ModalID, boolean>
  isAnyModalOpen: boolean
  closeModal: (id: ModalID) => void
  openModal: (id: ModalID) => void
}

const defaultModalContext: IModalContext = {
  isModalOpen: {
    abandon: false,
    acceptAllFacts: false,
    addTechnologyDomain: false,
    apiKey: false,
    autoPauseTask: false,
    chooseWatchlist: false,
    copyPortfolio: false,
    createDomain: false,
    createSubTheme: false,
    createTheme: false,
    createEstimation: false,
    createFactsSet: false,
    createPortfolioOld: false,
    createPortfolio: false,
    createUserGroup: false,
    createPSI: false,
    createMacroFactor: false,
    confirmMerge: false,
    dataChallenge: false,
    datapointViewer: false,
    deleteDataOriginUrl: false,
    deleteEstimation: false,
    deleteEstimationBranch: false,
    deleteKpi: false,
    deleteMacroFactor: false,
    deleteMetric: false,
    deleteMetricsConfig: false,
    deletePortfolio: false,
    deleteTechnology: false,
    deleteTechnologyCost: false,
    activateUser: false,
    deactivateUser: false,
    editActivity: false,
    editDomain: false,
    editEstimation: false,
    editPortfolio: false,
    editSubTheme: false,
    editTheme: false,
    estimationDetail: false,
    exportPortfolio: false,
    factViewer: false,
    portfolioUploadHelper: false,
    requestAssistance: false,
    termsOfService: false,
    unassignUser: false,
    updateAcceptedFact: false,
    uploadSource: false,
    noNextTask: false,
    getAccessSDG: false,
  },
  isAnyModalOpen: false,
  closeModal: () => {},
  openModal: () => {},
}

const ModalContext = React.createContext(defaultModalContext)

export const ModalProvider: FC<{ children: React.ReactNode }> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState({ ...defaultModalContext.isModalOpen })

  const isAnyModalOpen = Object.values(isModalOpen).some(Boolean)

  // NOTE: in future, this would be good to extend to check for conflicts. i.e.
  // if a modal is already open, to close it, or perhaps only if you're trying
  // to open the same modal twice (errors, confirmations etc).
  const openModal = useCallback(
    (id: ModalID) => setIsModalOpen((modalsState) => ({ ...modalsState, [id]: true })),
    [],
  )

  const closeModal = useCallback(
    (id: ModalID) => setIsModalOpen((modalsState) => ({ ...modalsState, [id]: false })),
    [],
  )

  return (
    <ModalContext.Provider
      value={{
        isModalOpen,
        isAnyModalOpen,
        closeModal,
        openModal,
      }}
      {...props}
    />
  )
}

export const useModalContext = (): IModalContext => {
  const context = React.useContext(ModalContext)
  if (context === defaultModalContext) {
    throw new Error('useModalContext must be used within a ModalProvider')
  }
  return context
}
