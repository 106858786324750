import { CSSProperties, FC, MouseEventHandler } from 'react'
import { lightTheme, ThemeColor } from '../../themes'
import { StyledSvg } from './Icon.style'
import { IconName, icons } from './icons'

export type IconSize = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl'

export type IconProps = {
  icon: IconName
  size?: IconSize
  width?: number
  height?: number
  viewbox?: string
  color?: ThemeColor
  alt: string
  ariaHidden?: boolean
  onClick?: MouseEventHandler<SVGSVGElement>
  style?: CSSProperties
  hideTooltip?: boolean | undefined
  children?: React.ReactNode
  inheritColor?: boolean
  className?: string
}

export const Icon: FC<IconProps> = ({
  icon,
  size = 's',
  width,
  height,
  viewbox = '0 0 24 24',
  color,
  alt,
  ariaHidden = false,
  onClick,
  style,
  hideTooltip,
  inheritColor = false,
  className,
}) => {
  const iconColor = color ? lightTheme.colors[color] : lightTheme.colors.typeSecondary
  const IconComponent = icons[icon]

  const useSizing = !width || !height

  let sizing = '0.75rem'
  if (size === 's') {
    sizing = '1.5rem'
  }
  if (size === 'm') {
    sizing = '3rem'
  }
  if (size === 'l') {
    sizing = '4.5rem'
  }
  if (size === 'xl') {
    sizing = '6rem'
  }
  if (size === 'xxl') {
    sizing = '14.75rem'
  }
  return (
    <StyledSvg
      aria-hidden={ariaHidden}
      role="img"
      fill="none"
      aria-label={alt}
      width={useSizing ? sizing : width}
      height={useSizing ? sizing : height}
      viewBox={viewbox}
      xmlns="http://www.w3.org/2000/svg"
      color={inheritColor ? undefined : iconColor}
      onClick={onClick}
      style={style}
      className={className}
    >
      {!hideTooltip && <title>{alt}</title>}
      <IconComponent />
    </StyledSvg>
  )
}
