import { createGlobalStyle } from 'styled-components'
import './fonts.css'

export const GlobalStyles = createGlobalStyle`
  .ant-popover-inner-content {
    padding: 0;
  }

  ul.ant-menu-vertical.ant-menu-sub {
    min-width: 0 !important;
    padding: 0 0.5rem;

    li {
      &.ant-menu-item a {
        height: 100%;
        padding: 0;
        display: flex;
        justify-content: start;
        align-items: center;
        transition: none;
        color: ${({ theme }) => theme.colors.typePrimary};
      }

      &.ant-menu-item.ant-menu-item-selected {
        background-color: transparent;
      }

      &.ant-menu-item-active div {
        color: ${({ theme }) => theme.colors.actionDarkLink};
      }
    }
  }

  .ant-select-item-option-selected {
    // Override selected dropdown options having a background of #404040
    background-color: ${({ theme }) => theme.colors.architecture3} !important;
  }

  .ant-table-filter-dropdown {
    border-radius: ${({ theme }) => theme.border.radiusSmall};
  }

  .ant-input::placeholder {
    color: ${({ theme }) => theme.colors.typeTertiary};
  }

  .ant-tabs-nav-list {
    border-bottom: none;
  }

  .ant-table-column-title {
    z-index: auto;
  }

  html {
    --search-input-height: 3.5rem;
    --search-input-width: 50.75rem;
  }

  [cmdk-input] {
    font-size: 1rem;
    line-height: 1.5rem;

    width: var(--search-input-width);
    height: var(--search-input-height);
    position: fixed;
    top: 10rem;
    left: calc((100vw - var(--search-input-width)) / 2);

    border: 1px solid ${({ theme }) => theme.colors.architecture3};
    background: ${({ theme }) => theme.colors.bgPrimary};
    outline: none;

    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10);

    text-indent: 1rem;
  }

  [cmdk-list] {
    width: var(--search-input-width);
    position: fixed;
    top: calc(10rem + var(--search-input-height));
    left: calc((100vw - var(--search-input-width)) / 2);
    background: ${({ theme }) => theme.colors.bgPrimary};
    border-radius: 0 0 0.75rem 0.75rem;
  }

  [cmdk-group] {
    color: ${({ theme }) => theme.colors.typeTertiary};
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 1rem;
    text-transform: uppercase;
    padding: 1rem;
  }

  [cmdk-loading] {
    padding: 0.5rem 1rem;
  }

  [cmdk-item] {
    color: ${({ theme }) => theme.colors.typePrimary};
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5rem;
    text-transform: none;
    cursor: pointer;

    display: flex;
    gap: 1rem;

    padding: 0.75rem 0.5rem;

    > svg {
      color: ${({ theme }) => theme.colors.typeTertiary};
    }

    &[data-selected='true'] {
      > svg {
        color: ${({ theme }) => theme.colors.typePrimary};
      }
    }
  }

`
