import { CreatePortfolio } from '@netpurpose/types'
import { formatDateForBackend } from '../datetime'
import { BackendPortfolio, Portfolio } from './parsePortfolio'

export type BackendCreatePortfolio = {
  portfolio_name: string
  total_value: string
  valuation_date: string
  portfolio_file: File
  benchmark: string
  identifier_col?: string
  weight_col?: string
  name_col?: string
}

export const formatCreatePortfolioRequest = (
  portfolio: CreatePortfolio,
): BackendCreatePortfolio => ({
  portfolio_name: portfolio.name,
  total_value: portfolio.totalValue.toString(),
  valuation_date: formatDateForBackend(portfolio.valuationDate),
  portfolio_file: portfolio.file,
  benchmark: portfolio.type === 'benchmark' ? 'true' : 'false',
  identifier_col: 'ISIN',
  weight_col: 'Weight',
  name_col: 'Name',
})

// Includes fields required to edit a portfolio.
// @ts-expect-error
export const formatPortfolio = (portfolio: Partial<Portfolio>): Partial<BackendPortfolio> => ({
  name: portfolio.name,
  total_value: portfolio.value,
  valuation_date: portfolio.valuationDate
    ? formatDateForBackend(portfolio.valuationDate)
    : undefined,
})
