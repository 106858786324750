import { CreatePortfolio } from '@netpurpose/types'
import { AxiosInstances } from '../../client'
import { joinPaths } from '../../utils'
import { convertToFormData } from '../convertToFormData'
import { SimpleAbstractModelApi } from '../SimpleAbstractModelApi'
import { formatCreatePortfolioRequest, formatPortfolio } from './formatPortfolio'
import {
  BackendPortfolio,
  parsePortfolio,
  Portfolio,
  reversePortfolioFieldMap,
} from './parsePortfolio'

type PortfolioApiCopyResponse = {
  new_portfolio_ids: Portfolio['id'][]
}

export default class PortfolioApi extends SimpleAbstractModelApi<BackendPortfolio, Portfolio> {
  endpoint = '/portfolios'
  reverseFieldMap = reversePortfolioFieldMap
  modelFormatter = formatPortfolio

  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances, parsePortfolio)
    this.upload = this.upload.bind(this)
    this.copy = this.copy.bind(this)
    this.createWatchlist = this.createWatchlist.bind(this)
    this.getAllWatchlists = this.getAllWatchlists.bind(this)
  }

  // TODO: override the create method from base class to avoid using non-implemented endpoints
  async upload(portfolio: CreatePortfolio) {
    const { data } = await this.queryBuilderApi.post(
      `${this.endpoint}/upload`,
      convertToFormData(formatCreatePortfolioRequest(portfolio)),
    )

    return data.result_id as string
  }

  async copy(id: Portfolio['id'], destinationGroupId: string): Promise<Portfolio['id'][]> {
    const { data } = await this.queryBuilderApi.post<PortfolioApiCopyResponse>(
      joinPaths(this.endpoint, id, 'copy'),
      {
        new_group_ids: [destinationGroupId],
      },
    )
    return data.new_portfolio_ids
  }

  async createWatchlist(name: string, entityIds: number[]): Promise<Portfolio> {
    const { data } = await this.queryBuilderApi.post<BackendPortfolio>(
      `${this.endpoint}/watchlist`,
      {
        name,
        entity_ids: entityIds,
      },
    )

    return parsePortfolio(data)
  }

  async getAllWatchlists(): Promise<Portfolio[]> {
    const {
      data: { results },
    } = await this.queryBuilderApi.get<{ total: number; results: BackendPortfolio[] }>(
      this.endpoint,
      {
        params: {
          type: 'watchlist',
        },
      },
    )

    return results.map(parsePortfolio)
  }

  async appendToWatchlist(id: number, entityIds: number[]): Promise<Portfolio> {
    const { data } = await this.queryBuilderApi.put<BackendPortfolio>(
      joinPaths(this.endpoint, 'watchlist', id),
      entityIds,
    )

    return parsePortfolio(data)
  }
}
