import { UserGroup, UserInfo } from '@netpurpose/types'
import { AxiosInstances } from '../../client'
import { BackendPaginationParams } from '../../queryBuilder'
import { AbstractApi } from '../AbstractApi'
import { PaginatedListResult } from '../AbstractModelApi'
import { parseUserInfo } from '../users'
import { BackendUserGroup, parseUserGroup } from './parseUserGroup'

export default class UserGroupsApi extends AbstractApi {
  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances)
    this.getGroups = this.getGroups.bind(this)
    this.getAllGroups = this.getAllGroups.bind(this)
    this.createGroup = this.createGroup.bind(this)
    this.updateGroup = this.updateGroup.bind(this)
  }

  async getGroups<Params extends BackendPaginationParams>(
    params: Params,
  ): Promise<PaginatedListResult<UserGroup>> {
    const {
      data: { results, total },
    } = await this.queryBuilderApi.get<{ total: number; results: BackendUserGroup[] }>('/groups', {
      params,
      headers: {
        'users-paginated-response': true,
      },
    })

    const hasNext = total - (params.page + 1) * params.limit > 0

    return {
      results: results.map((group: BackendUserGroup) => parseUserGroup(group)),
      hasNext,
      totalCount: total,
    }
  }

  async getAllGroups(): Promise<UserGroup[]> {
    const { data } = await this.queryBuilderApi.get('/groups')
    return data.results.map((group: BackendUserGroup) => parseUserGroup(group))
  }

  async getGroup(groupId: UserGroup['groupId']): Promise<UserGroup> {
    const { data } = await this.api.get<BackendUserGroup>(`/groups/${groupId}`)
    return parseUserGroup(data)
  }

  async createGroup({ name }: { name: string }): Promise<UserGroup> {
    const { data } = await this.api.post('/groups', { name })
    return parseUserGroup(data)
  }

  async updateGroup({
    name,
    groupId,
  }: {
    name: string
    groupId: UserGroup['groupId']
  }): Promise<UserGroup> {
    const { data } = await this.api.put(`/groups/${groupId}`, { name })
    return parseUserGroup(data)
  }

  async getGroupUsers(groupId: UserGroup['groupId']): Promise<UserInfo[]> {
    const { data } = await this.api.get(`/groups/${groupId}/users`)
    return data.map(parseUserInfo)
  }

  async updateGroupPermissions({
    permissions,
    groupId,
  }: {
    permissions: UserGroup['permissions']
    groupId: UserGroup['groupId']
  }): Promise<Permissions> {
    const { data } = await this.api.put(`/groups/${groupId}/permissions`, permissions)
    return data
  }
}
